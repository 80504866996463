import firebaseService from '../services/firebase-service';

function errorHandler(err) {
    console.error(err);
    throw err;
}

var token = null;

const LoginController = {
    getCurrentUser: () => {
        return firebaseService.auth.currentUser;
    },

    getCurrentUserToken: async () => {
        if (!token) {
            console.log('LoadToken');
            token = await firebaseService.auth.currentUser.getIdToken();
        }
        return token;
    },

    stateChanged: (cb) => {
        firebaseService.auth.onAuthStateChanged(cb);
    },

    registerUser: (name, email, password) => {
        return firebaseService
            .auth
            .createUserWithEmailAndPassword(email, password)
            .then(result => {
                const user = firebaseService.auth.currentUser;
                if (!user) throw new Error('User not found!');
                return user.updateProfile({ displayName: name })
            })
            .then(() => {
                return firebaseService.auth.signOut();
            })
            .then(() => {
                return true;
            })
            .catch(errorHandler)
    },

    updateUser: (updateData) => {
        const user = firebaseService.auth.currentUser;
        if (!user) return false;
        return user
            .updateProfile(updateData)
            .then(() => {
                return true;
            })
            .catch(errorHandler)
    },

    changeUserPassword: (newPassword) => {
        const user = firebaseService.auth.currentUser;
        if (!user) return false;
        return user
            .updatePassword(newPassword)
            .then(() => {
                return true;
            })
            .catch(errorHandler);
    },

    sendVerificationEmail: () => {
        const user = firebaseService.auth.currentUser;
        if (!user) return false;
        return user
            .sendEmailVerification()
            .then(() => {
                return true;
            })
            .catch(errorHandler);
    },

    sendResetPasswordEmail: (emailAddress) => {
        return firebaseService
            .auth
            .sendPasswordResetEmail(emailAddress)
            .then(() => {
                return true
            })
            .catch(errorHandler);
    },

    signInWithUser: (user, pass) => {
        return firebaseService
            .auth
            .signInWithEmailAndPassword(user, pass)
            .then(user => {                
                return user;
            })
            .catch(errorHandler)
    },

    signInWithGoogle: () => {
        const provider = new firebaseService.firebase.auth.GoogleAuthProvider();
        return firebaseService
            .auth
            .signInWithPopup(provider)
            .then(result => {
                // var token = result.credential.accessToken;
                var user = result.user;
                return user;
            })
            .catch(errorHandler);
    },

    signInWithFacebook: () => {
        const provider = new firebaseService.firebase.auth.FacebookAuthProvider();
        return firebaseService
            .auth
            .signInWithPopup(provider)
            .then(result => {
                // var token = result.credential.accessToken;
                var user = result.user;
                return user;
            })
            .catch(errorHandler);
    },

    signOut: () => {
        return firebaseService
            .auth
            .signOut()
            .then(() => {
                return true;
            })
            .catch(errorHandler);
    }
}

export default LoginController;