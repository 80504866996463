import React, { createContext, useContext, useState } from 'react';

const UserContext = createContext("");

export function UserProvider({ children }) {
    const [userAuthenticated, setUserAuthenticated] = useState("");

    return (
        <UserContext.Provider value={{ userAuthenticated, setUserAuthenticated }}>
            {children}
        </UserContext.Provider>
    );
}

export function useUser() {
    const context = useContext(UserContext);
    if (!context) throw new Error('useUser - Can not access context out of a provider');
    const { userAuthenticated, setUserAuthenticated } = context;
    return { userAuthenticated, setUserAuthenticated }
};