import React, { useEffect } from 'react';

const LoadingFullPage = ({ show, type }) => {
    //Loading Default on index.html
    useEffect(() => {
        return () => {
            document.querySelector('#loading').classList.add('hidden');
        }
    });

    if (!show) {
        return <div></div>;
    }

    if (!type) {
        document.querySelector('#loading').classList.remove('hidden');
        return <div></div>;
    }

    if (type === "spinner") {
        return (
            <div
                className='position-fixed h-100 w-100 m-0 p-0 d-flex flex-column justify-content-center align-items-center'
                style={{ background: `rgba(0,0,0,0.5)`, width: "100%", top: 0, left: 0, zIndex: 9999, overflow: "hidden" }}
            >
                <i className="fa fa-spinner fa-pulse fa-3x fa-fw text-white"></i>
                <span className="sr-only">Loading...</span>
            </div>
        );
    }
};

export default LoadingFullPage;
