import React, { useState, createContext, useContext } from 'react';

const ModalRegistersContext = createContext();

export function ModalRegistersProvider({ children }) {
    const [modalIncomeIsOpen, setModalIncomeIsOpen] = useState(false);
    const [modalContactIsOpen, setModalContactIsOpen] = useState(false);
    const [modalExpenseIsOpen, setModalExpenseIsOpen] = useState(false);
    const [modalSpecialIsOpen, setModalSpecialIsOpen] = useState(false);

    const value = {
        modalIncomeIsOpen,
        setModalIncomeIsOpen,
        modalContactIsOpen,
        setModalContactIsOpen,
        modalExpenseIsOpen,
        setModalExpenseIsOpen,
        modalSpecialIsOpen,
        setModalSpecialIsOpen
    }

    return (
        <ModalRegistersContext.Provider value={value}>
            {children}
        </ModalRegistersContext.Provider>
    );
}

export function useModalRegisters() {
    const context = useContext(ModalRegistersContext);

    if (!context) throw new Error('useModalRegisters - Can not access context out of a provider');

    const {
        modalIncomeIsOpen,
        setModalIncomeIsOpen,
        modalContactIsOpen,
        setModalContactIsOpen,
        modalExpenseIsOpen,
        setModalExpenseIsOpen,
        modalSpecialIsOpen,
        setModalSpecialIsOpen
    } = context;

    return {
        modalIncomeIsOpen,
        setModalIncomeIsOpen,
        modalContactIsOpen,
        setModalContactIsOpen,
        modalExpenseIsOpen,
        setModalExpenseIsOpen,
        modalSpecialIsOpen,
        setModalSpecialIsOpen
    }
}
