import React, { useEffect, useState } from 'react';
import {
    Route,
    Redirect
} from "react-router-dom";

import LoginController from '../controllers/login-controller';

const PrivateRoute = ({ children, ...rest }) => {
    const [ready, setReady] = useState(false);

    useEffect(() => {
        LoginController
            .stateChanged((logged) => {
                setReady(true)
            });
    }, []);

    if (!ready) {
        return <div></div>;
    }

    return (
        <Route
            {...rest}
            render={
                ({ location }) =>
                    (!!LoginController.getCurrentUser()) ?
                        (children) :
                        (<Redirect to={{ pathname: "/login", state: { from: location } }} />)
            }
        />
    );
}

export default PrivateRoute;