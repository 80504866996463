import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

var firebaseConfig = {
    apiKey: "AIzaSyA0SLKm1qarQa9d_DpRiwa2GuMQNMlVplg",
    authDomain: "ameicontabilidadeonline.firebaseapp.com",
    databaseURL: "https://ameicontabilidadeonline.firebaseio.com",
    projectId: "ameicontabilidadeonline",
    storageBucket: "ameicontabilidadeonline.appspot.com",
    messagingSenderId: "623765590456",
    appId: "1:623765590456:web:c18566cead2ff280a2bf4d",
    measurementId: "G-7F1BG85ZVX"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

// APIs
const auth = firebase.auth();
auth.languageCode = 'pt-br'

const db = firebase.firestore();

const storage = firebase.storage();

export default { firebase, auth, db, storage };