import React, { lazy, Suspense } from 'react';

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import PrivateRoute from './utils/PrivateRoute-util';
import LoadingFullPage from './utils/LoadingFullPage-util';

import { UserProvider } from './hooks/useUser';
import { ModalRegistersProvider } from './hooks/useModalRegisters';

/* eslint-disable import/first */
const Login = lazy(() => import('./pages/Login-page'));

const Register = lazy(() => import('./pages/Register-page'));

const App = lazy(() => import('./pages/App-page'));

const CreateCompany = lazy(() => import('./extras/createCompany/CreateCompany-page'));

const CreateCompanyTrack = lazy(() => import('./extras/createCompany/createCompanyTrack-page'));

const PlanUpgrade = lazy(() => import('./extras/upgradePlan/PlanUpgrade-page'));

const AdminPage = lazy(() => import('./extras/admin/admin-page'));


const AppRoute = () => {
	const renderLoader = () => <LoadingFullPage show={true} />;

	return (
		<Suspense fallback={renderLoader()}>
			<Router>
				<Switch>
					<PrivateRoute exact path="/">
						<Redirect to="/app" />
					</PrivateRoute>

					<PrivateRoute exact path="/logout">
						<Redirect to="/" />
					</PrivateRoute>

					<Route exact path="/login">
						<Login />
					</Route>

					<Route exact path="/register">
						<Register />
					</Route>

					<Route exact path="/create/company">
						<CreateCompany />
					</Route>

					<Route path="/create/company/track">
						<CreateCompanyTrack />
					</Route>

					<PrivateRoute exact path="/upgrade/plan">
						<PlanUpgrade />
					</PrivateRoute>

					<PrivateRoute path="/admin">
						<AdminPage />
					</PrivateRoute>

					<PrivateRoute path="/app">
						<UserProvider>
							<ModalRegistersProvider>
								<App />
							</ModalRegistersProvider>
						</UserProvider>
					</PrivateRoute>
				</Switch>
			</Router>
		</Suspense>
	);
}

export default AppRoute;
